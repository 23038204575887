import React, {useState} from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import Header from "./ant/Header"
import Footer from "./ant/Footer"

const Layout = ({ scrollTo, isHomePage, children }) => {
  const [state, setState] = useState({showShadow: false})

  return (
    <>
    <Header scrollTo={scrollTo} isHomePage={isHomePage} key="header" className={state.showShadow ? 'show-shadow' : ''} />
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      {/* <header className="global-header">
        {isHomePage ? (
          <h1 className="main-heading">
            <Link to="/">{parse(title)}</Link>
          </h1>
        ) : (
          <Link className="header-link-home" to="/">
            {title}
          </Link>
        )}
      </header> */}

      <main>{children}</main>

      {/* <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
        {` `}
        And <a href="https://wordpress.org/">WordPress</a>
      </footer> */}
      {/* <DocumentTitle title="Ant-V" /> */}
    </div>
    <Footer key="footer"/>
    </>
  )
}

export default Layout
