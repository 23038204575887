import React from 'react';
import { Button } from 'antd';
import {Link} from 'gatsby';
import { HomeFilled } from '@ant-design/icons';

export default function Header({scrollTo, isHomePage, ...props}) {
  return (
    <header {...props}>
      <a className="logo-wrapper" href="/">
        <i className="logo" />
        <span>Klepon Tech.</span>
      </a>
      <div className="button">
        {isHomePage ? <Button onClick={scrollTo}>Tertarik Bekerjasama</Button> : 
            <Link style={{color: 'black', fontSize: 16}} to="/"><HomeFilled /> Kembali</Link>}
      </div>
    </header>
  );
}