import React from 'react';
import { Row, Col } from 'antd';
import parse from "html-react-parser";
import { useStaticQuery, graphql } from "gatsby"

function Footer() {

  const { contact, about } = useStaticQuery(graphql`
    query FooterQuery {
      contact: allWpPage(filter: {parentId: {eq: "cG9zdDoxNg=="}}) {
        edges {
          node {
            id
            title
            content
          }
        }
      }
      about: wpPage(slug: {eq: "about-us"}) {
        slug
        id
        title
        content
      }
    }
  `)

  const footers = contact.edges.map(x => x.node);

  return (
    <footer id="footer" className="dark">
      <div className="footer-wrap">
        <Row>
            <Col lg={6} sm={24} xs={24}>
              <div className="footer-center">
                <h2>{about.title}</h2>
                {parse(about.content)}
              </div>
            </Col>
          {
            footers.map((x,i) => <Col lg={6} sm={24} xs={24} key={i.toString()}>
              <div className="footer-center">
                <h2>{x.title}</h2>
                {parse(x.content)}
              </div>
            </Col>)
          }
        </Row>
      </div>
      <Row className="bottom-bar">
        <Col lg={6} sm={24} style={{textAlign: 'left'}}>
          <a target="_blank" href="http://gse.co.id">CV. General Service Engineering</a>
        </Col>
        <Col lg={18} sm={24}>
          <span style={{ marginRight: 12 }}>Copyright © Klepon Tech.</span>
        </Col>
      </Row>
    </footer>
  );
}


export default Footer;