import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import {Button, Carousel, message, Modal, Tag} from 'antd';

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RequestForm from "../pages/RequestForm"

const findGalleryAndReplaceToCarousel = (content) => {
  const parsed = parse(content);
  //get only gallery in block content and set the others to null
  const gallery = parsed.reduce((p, c) => [...p, c.props && c.props.className && c.props.className.indexOf("wp-block-gallery") > -1 ? c : null])
                        .map(x => x?.props || null);
  //get index gallery in the blocks
  const galleryBlockIndex = gallery.map((x,i) => x !== null ? i : -1).filter(x => x >= 0);

  //replace blocks gallery to new React Element
  return parsed.map((x, i) => {
    if (galleryBlockIndex.indexOf(i) > -1) {
      // get block gallery grid
      const blockGalleryGrid = x.props.children.props;
      // get figure child
      const figures = blockGalleryGrid.children.map(item => item.props);
      console.log(figures);
      // convert to carousel
      return (<Carousel autoplay key={i.toString()}>
        {figures.map((f, j) => <div key={`${i}_${j}`} {...f}></div>)}
      </Carousel>)
    } else {
      return x;
    }
  })
}

const BlogPostTemplate = ({data: {previous, post, next} }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  const categories = post?.categories?.nodes?.map(x => x.name) || [];
  const tags = post?.tags?.nodes?.map(x => x.name) || [];

  const [isOpened, showModal] = React.useState(false)

  return (
    <>
    <Layout>
      <SEO title={post.title} description={post.excerpt} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <h1 itemProp="headline">{parse(post.title)}</h1>

        <p>{post.date}</p>

        {/* if we have a featured image for this post let's display it */}
        {featuredImage?.fluid && (
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
            style={{ marginBottom: 50 }}
          />
        )}

        {!!post.content && (
          <section itemProp="articleBody">
            {findGalleryAndReplaceToCarousel(post.content)}
            <br/>
            <div
              style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '10px 0'}}>
            {
              categories.find(x => x === 'Portfolio') && 
              <a href="#" onClick={() => showModal(true)} key="a">
                <Button type="primary">
                  Minta Demo
                </Button>
              </a>
            }
            </div>
            <div style={{textAlign: 'right'}}>
              {tags.map(x => <Tag key={x}>{x}</Tag>)}
            </div>
          </section>
        )}

        <hr />

        <footer>
          <Bio />
        </footer>
      </article>

      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            // flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: '20px 0',
          }}
        >
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>
          <li style={{flex: 1, minWidth: 100}}></li>
          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>

    <Modal
      title="Permintaan Demo"
      visible={isOpened}
      footer={null}
      onCancel={() => showModal(false)}
    >
      <RequestForm isPopup={true} projectTitle={post.title} handleSubmit={({data, success}) => {
        if (success) {
          message.success("💖 Terima kasih atas permintaan yang telah dikirimkan, kami akan 📞 anda kembali 💖", 10);
        }
      }} />
    </Modal>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
          uri
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
